import type { ManagedServiceEnum, ServiceImage } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { first, last } from "remeda";
import type { SelectedOs } from "../../compute/os/os.ts";
import type { ManagedServiceLoc } from "../servicesQuery.ts";

export interface ServiceOs extends SelectedOs {
    os_name: ManagedServiceEnum;
    os_version: string;

    /** Prevent extra fields and force usage of {@link extractServiceOs} */
    service?: never;
}

export function extractServiceOs(obj: ManagedServiceLoc): ServiceOs {
    return { os_name: obj.service, os_version: obj.version };
}

export function getDefaultServiceOs(images: ServiceImage[]): ServiceOs {
    const defaultImage = notNull(first(images), "No default image found");
    return {
        os_name: defaultImage.service_name,
        os_version: getDefaultServiceVersion(defaultImage),
    };
}

function getDefaultServiceVersion(image: ServiceImage) {
    return notNull(last(image.versions), "No default version found");
}
